<template>
  <v-container fluid class="py-0" :class="{'pa-0': !pad}" v-if="round.bracketPlay && bracket">
      <v-row dense>
        <v-col cols="12" class="pa-0 text-right">
          <v-btn
            color="color3"
            class="xsmall pa=0 ma-0"
            text
            @click.stop="advanced = !advanced"
            :disabled="!edit|| forceAdv"
          >{{advanced ? 'simple' : 'advanced'}}</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-select
            :items="bracketTypes"
            label="Bracket Type"
            :disabled="!edit"
            v-model="bracket.type"
            color="color3"
            item-color="color3"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-expand-transition>
            <v-row dense v-if="showAdv">
              <v-switch
                label="Duals"
                v-model="bracket.props"
                :value="division.dualProp"
                v-if="division.isDuals"
                color="success"
                class="shrink mr-3"
                :disabled="!edit"
              ></v-switch>
              <v-switch
                label="Auto Court"
                v-model="autoCourt"
                color="success"
                :disabled="!edit"
                class="shrink mr-3"
              ></v-switch>
              <v-switch
                label="Auto Refs"
                v-model="autoRefs"
                color="success"
                :disabled="!edit"
                class="shrink mr-3"
              ></v-switch>
              <v-switch
                label="Avoid Same Pool Matchup"
                v-model="avoidMatchups"
                color="success"
                :disabled="!edit"
                class="shrink mr-3"
              ></v-switch>
              <v-expand-transition>
                <v-switch
                  v-if="!autoRefs && false"
                  label="Previous Loser"
                  v-model="bracket.prevLoser"
                  color="success"
                  :disabled="!edit"
                  class="shrink"
                ></v-switch>
              </v-expand-transition>
              <v-switch
                label="Is Qualifier"
                v-model="isQualiy"
                :disabled="!edit"
                v-if=" bracket.type !== 'SINGLE_ELIM_CHALLENGE'"
                color="success"
              ></v-switch>
            </v-row>
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-if="isQualiy">
          <v-row dense>
            <v-col cols="6" sm="3">
              <v-select
                v-if="isQualiy"
                :items="[2,3,4,5,6,8,9,12,16]"
                v-model="bracket.champions"
                label="Qualifying Teams"
                :disabled="!edit"
                color="color3"
                item-color="color3"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row dense>
        <v-col cols="12" class="title">
          Match Settings
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="elevation-1">
            <v-toolbar dense :color="edit ? 'color2 color2Text--text' : 'grey lighten-3'" flat>
              <v-toolbar-title>Winners Bracket</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0 px-0">
              <match-settings
                :setting="bracket.winnersMatchSettings"
                :edit="edit"
              ></match-settings>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" v-if="bracket.isDouble">
          <v-card class="elevation-1">
            <v-toolbar dense :color="edit ? 'color2 color2Text--text' : 'grey lighten-3'" flat>
              <v-toolbar-title>{{bracket.losersName}} Bracket</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0 px-0">
              <match-settings
                v-if="bracket.losersMatchSettings"
                :setting="bracket.losersMatchSettings"
                :edit="edit"
              ></match-settings>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" v-if="bracketType && bracketType.hasPlaceBracket && false">
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="500px"
            transition="dialog-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="color3"
                text
                v-on="on"
              >additional settings</v-btn>
            </template>
            <v-card>
              <v-toolbar color="color2 color2Text--text">
                <v-toolbar-title>Bracket Additional Settings</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="color1 color1Text--text"
                  fab small @click.stop="dialog = false"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  label="Winners Bracket Name"
                  v-model="wbName"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { BracketTypes } from '@/classes/BracketTypes'
import MatchSettings from './MatchSettings.vue'

export default {
  props: ['round', 'edit', 'pad', 'division'],
  data () {
    return {
      advanced: false,
      qualiy: false,
      champions: 4,
      dialog: false,
      wbName: null,
      lbName: null
    }
  },
  computed: {
    bracket () {
      return this.round.brackets[0]
    },
    loserName () {
      return this.bracket && this.bracket.type.includes('5TH') ? '5th Place' : 'Contenders'
    },
    bracketTypes () {
      return BracketTypes // .filter(f => f.value === 'SINGLE_ELIM')
    },
    bracketType () {
      return BracketTypes.find(f => f.value === this.bracket.type)
    },
    isDouble () {
      return this.bracket && (this.bracket.type.startsWith('DOUBLE') || this.bracket.type.includes('5TH'))
    },
    showAdv () {
      return this.bracket && (this.bracket.isQualifier || this.advanced || this.division.isDuals || !this.autoCourt || !this.autoRefs)
    },
    forceAdv () {
      return this.bracket && (this.bracket.isQualifier || this.division.isDuals || !this.autoCourt || !this.autoRefs)
    },
    isQualiy: {
      get: function () {
        return this.bracket.champions > 1
      },
      set: function (val) {
        if (val) {
          this.bracket.champions = 4
        } else {
          this.bracket.champions = 1
        }
      }
    },
    isChallenge () {
      return this.bracket && this.bracket.isChallenge
    },
    autoCourt: {
      get () {
        return !this.bracket.props.includes('noCourts')
      },
      set (val) {
        if (val) {
          this.bracket.props = this.bracket.props.filter(f => f !== 'noCourts')
        } else {
          this.bracket.props.push('noCourts')
        }
      }
    },
    avoidMatchups: {
      get () {
        return this.bracket.props.includes('avoidMatchups')
      },
      set (val) {
        if (val) {
          this.bracket.props.push('avoidMatchups')
        } else {
          this.bracket.props = this.bracket.props.filter(f => f !== 'avoidMatchups')
        }
      }
    },
    autoRefs: {
      get () {
        return !this.bracket.manualRefs
      },
      set (val) {
        this.bracket.manualRefs = !val
      }
    }
  },
  methods: {
  },
  watch: {
    isDouble: function (n, o) {
      if (n && !this.bracket.losersMatchSettings) {
        this.bracket.addLosers()
      }
    },
    isChallenge: function (val) {
      if (val && this.bracket.champions === 1) {
        this.bracket.champions = 8
      }
      if (!val && this.bracket.champions !== 1) {
        this.bracket.champions = 1
      }
    },
    edit: function (val) {
      this.advanced = this.forceAdv
    }
  },
  mounted () {
    if (this.forceAdv) this.advanced = true
  },
  components: {
    'match-settings': MatchSettings
  }
}
</script>

<style>

</style>
